import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-bottom-sheet-product',
  templateUrl: './bottom-sheet-product.component.html',
  styleUrls: ['./bottom-sheet-product.component.css']
})
export class BottomSheetProductComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetProductComponent>) {
  }

  ngOnInit(): void {
  }

}
