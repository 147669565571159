export class Product {
  id: number;
  productName: string;
  imageUrl: string;
  category: [ ];
  priceText: string;
  oldPrice: any;
  newPrice: any;
  storeName: string;
  discountAmount: string;
  discountPercent: number;
  createdDate: Date;
  endDate: Date;
}
