import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
/*
for authentication during testing the following changes were made:
2 files auth.service
2 files auth,guard
login component
changes to the app-routing
*/
export class LoginComponent implements OnInit {
  form: FormGroup;
 
    public loginInvalid: boolean;
  
    private formSubmitAttempt: boolean;
 
    private returnUrl: string;

    constructor(
      private fb: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authService: AuthService
    ) {
    }
    async ngOnInit() {
  this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/products';

  this.form = this.fb.group({
          username: ['', Validators.required],
          password: ['', Validators.required]
        });
        // if (await this.authService.checkAuthenticated()) {
          if (await this.authService.isAuthenticate) {
                await this.router.navigate([this.returnUrl]);
              }
            }

            async onSubmit() {
              console.log("inside onsubmit login.ts")
                  this.loginInvalid = false;
              
                  this.formSubmitAttempt = false;
              
                  if (this.form.valid) {
              
                    try {
              
                      const username = this.form.get('username').value;
              
                      const password = this.form.get('password').value;
              if(this.authService.login(username, password))
                     {this.router.navigate(['/products']);}
              
                    } catch (err) {
              
                      this.loginInvalid = true;
              
                    }
              
                  } else {
              
                    this.formSubmitAttempt = true;
              
                  }
              
                }
              
              }
  