<div  style="height: 60px"></div>
<mat-card>
    
      <mat-card-content>
    
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
    
          <h2>App in Testing Authorization Required</h2>
    
          <mat-error *ngIf="loginInvalid">
    
            The username and password were not recognised
    
          </mat-error>
    
          <mat-form-field class="full-width-input">
    
            <input matInput placeholder="Name" formControlName="username" required>
    
            <mat-error>
    
              Please provide a valid user name
    
            </mat-error>
    
          </mat-form-field>
    
          <mat-form-field class="full-width-input">
    
            <input matInput type="password" placeholder="Password" formControlName="password" required>
    
            <mat-error>

              Please provide a valid password
    
            </mat-error>
    
          </mat-form-field>
    
          <button mat-raised-button color="primary">Login</button>
    
        </form>
    
      </mat-card-content>
    
    </mat-card>