import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { BottomSheetProductComponent } from 'src/app/bottom-sheet-product/bottom-sheet-product.component';
import { BottomSheetComponent } from 'src/app/bottom-sheet/bottom-sheet.component';
import { ProductService } from 'src/app/product/product.service';
import { SharedDataService } from 'src/app/service/shared-data.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations:[
    trigger('fade', [
      transition('void => active', [ // using status here for transition
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]), 
      transition('* => void', [
        animate(1000, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();
totalSavingsNumber: number;
status = "active";
formGroup : FormGroup;
filteredOptions;
  searchValue2 = '';
  options: string[] = [
    'Afghanistan',
'Albania',
'Algeria',
'Andorra',
'Angola',
'Antigua & Deps',
'Argentina',
'Armenia',
'Australia',
'Austria',
'Azerbaijan',
'Bahamas',
'Bahrain',
'Bangladesh',
'Barbados',
'Belarus',
'Belgium',
'Belize',
'Benin',
'Bhutan',
'Bolivia',
'Bosnia Herzegovina',
'Botswana',
'Brazil',
'Brunei',
'Bulgaria',
'Burkina',
'Burundi',
'Cambodia',
'Cameroon',
'Canada',
'Cape Verde',
'Central African Rep',
'Chad',
'Chile',
'China',
'Colombia',
'Comoros',
'Congo',
'Costa Rica',
'Croatia',
'Cuba',
'Cyprus',
'Czech Republic',
'Denmark',
'Djibouti',
'Dominica',
'Dominican Republic',
'East Timor',
'Ecuador',
'Egypt',
'El Salvador',
'Equatorial Guinea',
'Eritrea',
'Estonia',
'Ethiopia',
'Fiji',
'Finland',
'France',
'Gabon',
'Gambia',
'Georgia',
'Germany',
'Ghana',
'Greece',
'Grenada',
'Guatemala',
'Guinea',
'Guinea-Bissau',
'Guyana',
'Haiti',
'Honduras',
'Hungary',
'Iceland',
'India',
'Indonesia',
'Iran',
'Iraq',
'Ireland',
'Israel',
'Italy',
'Ivory Coast',
'Jamaica',
'Japan',
'Jordan',
'Kazakhstan',
'Kenya',
'Kiribati',
'Korea North',
'Korea South',
'Kosovo',
'Kuwait',
'Kyrgyzstan',
'Laos',
'Latvia',
'Lebanon',
'Lesotho',
'Liberia',
'Libya',
'Liechtenstein',
'Lithuania',
'Luxembourg',
'Macedonia',
'Madagascar',
'Malawi',
'Malaysia',
'Maldives',
'Mali',
'Malta',
'Marshall Islands',
'Mauritania',
'Mauritius',
'Mexico',
'Micronesia',
'Moldova',
'Monaco',
'Mongolia',
'Montenegro',
'Morocco',
'Mozambique',
'Myanmar',
'Namibia',
'Nauru',
'Nepal',
'Netherlands',
'New Zealand',
'Nicaragua',
'Niger',
'Nigeria',
'Norway',
'Oman',
'Pakistan',
'Palau',
'Panama',
'Papua New Guinea',
'Paraguay',
'Peru',
'Philippines',
'Poland',
'Portugal',
'Qatar',
'Romania',
'Russian Federation',
'Rwanda',
'St Kitts & Nevis',
'St Lucia',
'Saint Vincent & the Grenadines',
'Samoa',
'San Marino',
'Sao Tome & Principe',
'Saudi Arabia',
'Senegal',
'Serbia',
'Seychelles',
'Sierra Leone',
'Singapore',
'Slovakia',
'Solomon Islands',
'Somalia',
'South Africa',
'South Sudan',
'Spain',
'Sri Lanka',
'Sudan',
'Suriname',
'Swaziland',
'Sweden',
'Switzerland',
'Syria',
'Taiwan',
'Tajikistan',
'Tanzania',
'Thailand',
'Togo',
'Tonga',
'Trinidad & Tobago',
'Tunisia',
'Turkey',
'Turkmenistan',
'Tuvalu',
'Uganda',
'Ukraine',
'United Arab Emirates',
'United Kingdom',
'United States',
'Uruguay',
'Uzbekistan',
'Vanuatu',
'Vatican City',
'Venezuela',
'Vietnam',
'Yemen',
'Zambia',
'Zimbabwe'

  ];
  // filteredOptions: Observable<string[]>;
  myControl = new FormControl();
  createdDate: Date;

  // boolean to hide or show other icons in the menu
  searchOpenState = false; 
  private _decimalPipe: any;

    // get's the user discount percent which is in the product service
    get userDiscountPercent(): number {
      return this.productService.userDiscountPercent;
    }

  constructor(private _bottomSheet: MatBottomSheet,
             private productService: ProductService,
             private sharedDataService: SharedDataService,
             public authService: AuthService,
             private fb : FormBuilder) { }

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );  
      // this.sharedDataService.totalSavingsNumber.subscribe(value => {...});
      // this.totalSavingsNumber = this.sharedDataService.getTotalSavingsNumber;
      this.sharedDataService.getValue().subscribe((value) => {
        this.totalSavingsNumber = value;
        // this.totalSavingsNumber = this._decimalPipe.transform(this.totalSavingsNumber, '1.2-2');  
      });

    this.initForm();
    this.getNames();
              
  }

  // getTotalSavingsNumber(){
  //   this.totalSavingsNumber =  this.sharedDataService.totalSavingsNumber;
  //   console.log(' getTotalSavingsNumber',  this.totalSavingsNumber);
    
  // }

  
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  onToggleSearchState() {
    this.searchOpenState = ! this.searchOpenState;
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }
  // this will call the bottom sheet
  openBottomSheet(): void {
    this._bottomSheet.open(BottomSheetComponent);
    // this._bottomSheet.open(BottomSheetComponent).afterDismissed().subscribe((str) => {
    //   console.log(str);
    //   this.badgePercentage = str;

    // });
  }

  openProductBottomSheet(): void {
    this._bottomSheet.open(BottomSheetProductComponent);
    this.createdDate = this.productService.productList[0].createdDate;
    console.log('this.createdDate', this.createdDate);
  }

  // method to call the top products function
  topProducts(): void {
    this.productService.isTopProducts = true;
    // this will populate an observabile in the service that can be accessed by the products file for pagination
    // by using a method call and checking the string
    this.productService.sendisTopProductString("topProducts");
    this.productService.getTopProducts({ page: "0", size: "20" });
  }

  test(test){
    console.log("test the search " + test);
  }

  initForm(){
    this.formGroup = this.fb.group({
      'productSearch' : ['']
    })
    this.formGroup.get('productSearch').valueChanges.subscribe(response => {
      // console.log('data is ', response);
      // console.log('data is length ', response.length);
      if (response.length > 1) {
      this.filterData(response);
      }
      if (response.length >= 3) {
        this.filteredOptions.unshift(response);
      }
      // console.log('this.filteredOptions', this.filteredOptions);
      
    })
  }

  filterData(enteredData){
    this.filteredOptions = this.options.filter(item => {
      // console.log('filtered data', item.toLowerCase().indexOf(enteredData.toLowerCase()) > -1);
      // console.log('item', item);
      // console.log('item', item.length); item is the item found
      
      return item.toLowerCase().indexOf(enteredData.toLowerCase()) > -1
    })
  }

  getNames(){
    this.productService.getData().subscribe(response => {
      this.options = response;
      this.filteredOptions = response;
    })
  }

  // after the user selects from the search, set the pagination variable to true so there is no clash wiht the top products call
  selectedOption(option) {
    console.log(option);
    this.sharedDataService.searchName = option;
    this.productService.isSearchProducts = true;
    this.productService.isTopProducts = false;
    this.getProductsBySearchNamePageination(option);
    
  }

       // method that gets discounted list of products based on user input
       private getProductsBySearchNamePageination(option) {
        // this.loading = true;
        this.productService.getProductsBySearchName(option);
       }
 
}
