import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProductListComponent } from './product/product-list/product-list.component';


const routes: Routes = [

  
  { path: '', component: LoginComponent },
  // { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  // { path: 'products', component: ProductListComponent, canActivate: [AuthGuard]}
  { path: 'home', component: HomeComponent},
  { path: 'products', component: ProductListComponent }
  // { path: '', redirectTo: '/home', pathMatch: 'full', canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
