import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ProductFilter } from '../product-filter';
import { ProductService } from '../product.service';
import { Product } from '../product';

import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ProductViewComponent } from '../product-view/product-view.component';
import { MatSlideToggleChange } from '@angular/material';
import { SharedDataService } from 'src/app/service/shared-data.service';


@Component({
  selector: 'app-product',
  templateUrl: 'product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  checked = false;
  selected = 'Supervalu';
  products: Product[] = [];
  loading: boolean;

  filter = new ProductFilter();
  selectedProduct: Product;
  feedback: any = {};

  isFilteredByDiscountPercent : boolean; // variable to identify to the next page call for pagenation

  // get the value of the total elements used for pegination from the product service
  get totalElements(): number {
    return this.productService.totalElements;
  }

  get productList(): Product[] {
    return this.productService.productList;
  }

    // get's the user discount percent which is in the product service
    // this may not be needed here
    get userDiscountPercent(): number {
      return this.productService.userDiscountPercent;
    }

  constructor(private productService: ProductService, private sharedDataService: SharedDataService, public dialog: MatDialog) {
    // subscribe to observable and call method to reset pagenation pages  
    this.productService.isTopProduct$.subscribe(value => this.checkForDifferentListOfProducts(value));
  }

  //check string passed and clear pagenation if appropiate
  checkForDifferentListOfProducts(value) {
    if (value === "topProducts") {
      this.paginator.pageIndex = 0;
       this.paginator.pageSize = 20;
    }
  }
  ngOnInit() {
    // this.getProductsWithDiscountPercentPageination(100);
    // this.search();

    // using the call with pagenation

    // this.getProductsPageination({ page: "0", size: "20" }); this is working fine
    this.productService.getTopProducts({ page: "0", size: "20" });
    this.productService.isTopProducts = true;

  }


  // method that gets the full product list
  // need to refactor this method to remove the subscribe part and have this done in the product service
  private getProductsPageination(request) {
    this.loading = true;
    this.productService.listProducts(request)
      .subscribe(data => {
        this.products = data['content'];
        this.productService.totalElements = data['totalElements'];
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }

    // method that gets discounted list of products based on user input
    private getProductsDiscountPercentPageination(request) {
      // this.loading = true;
      this.productService.findByDiscountPercent(request);
        // .subscribe(data => {
        //   this.products = data['content'];
        //   this.totalElements = data['totalElements'];
        //   this.loading = false;
        // }, error => {
        //   this.loading = false;
        // });
    }
  

    // temp disabled
  // private getProductsWithDiscountPercentPageination(request) {
  //   this.loading = true;
  //   this.productService.findByDiscountPercent(request, this.userDiscountPercent)
  //     .subscribe(data => {
  //       this.products = data['content'];
  //       this.totalElements = data['totalElements'];
  //       this.loading = false;
  //     }, error => {
  //       this.loading = false;
  //     });
  // }
 
  // called when you change a page in pagenation. 
  nextPage(event: PageEvent) {
    const request = {};
    // build request
    request['page'] = event.pageIndex.toString(); 
    request['size'] = event.pageSize.toString();

    //todo add a button that will show top products and set the is top products boolean back to true
  
    // this is set to true by default as this top products list is the initial call when you open the app
    if(this.productService.isTopProducts){
    this.productService.getTopProducts(request);
    }
    
    if(!this.productService.isSearchProducts) {
      // make the call that will filter all products based on discount percent
     this.getProductsDiscountPercentPageination(request);
    }

    if(this.productService.isSearchProducts) {
      this.productService.getProductsBySearchName(request, this.sharedDataService.searchName);
    }

    const matTable= document.getElementById('matTable');
    matTable.scrollIntoView();
  }

  // search(): void {
  //   this.productService.load(this.filter);
  // }

  select(selected: Product): void {
    this.selectedProduct = selected;
  }

  // delete(product: Product): void {
  //   if (confirm('Are you sure?')) {
  //     this.productService.delete(product).subscribe(() => {
  //         this.feedback = {type: 'success', message: 'Delete was successful!'};
  //         setTimeout(() => {
  //           // this.search();
  //         }, 1000);
  //       },
  //       err => {
  //         this.feedback = {type: 'warning', message: 'Error deleting.'};
  //       }
  //     );
  //   }
  // }

  // this method will put the product into the current product field in the product service.
  // this is accessed bt the product-view.
  onSelected(product: Product) {
    this.productService.currentProduct = product;
    this.openDialog();
  }

  // this will open the view popup 
  openDialog(): void {
    const dialogRef = this.dialog.open(ProductViewComponent, {
      width: '250px'
      // ,
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  // method to reduce the product name if too long and add a new line if too small
  // uses to enable the material cards to be the same size without giving them a size
  // as this would effect different views like mobile, landscape etc
  formatProductName(productName: String ) {
    // console.log("product name", productName);
    if (productName && productName.length>24) {
        return productName.slice(0, 24) +' ...';
    }
    if (productName && productName.length<20) {
      return '\n' + productName;
  }
     return productName;
  }

  // this method will return the amount of money that you save 
  // i.e. old price - new price
  getAmountsaved(product: Product) {
    // console.log( 'product' , product);
    // console.log((product.oldPrice - product.newPrice).toFixed(2));
    return ('€' +(product.oldPrice - product.newPrice).toFixed(2));
  }

  onChange($event: MatSlideToggleChange, product: Product) {
    console.log($event);
    console.log('product', product);
    if($event.checked) {
      // this.sharedDataService.totalSavingsNumber + 10;
      // this.sharedDataService.setValue(+this.sharedDataService.getValue +Number(product.discountAmount));
      this.sharedDataService.addValue(Number(product.discountAmount));
      // console.log('added', this.sharedDataService.getValue);  
    } else {
      // this.sharedDataService.setValue(+this.sharedDataService.getValue -Number(product.discountAmount));
      this.sharedDataService.subtractValue(Number(product.discountAmount));
      // console.log('subtracted', this.sharedDataService.getValue);
    }
    
}

 

}
