<!-- trying to contain the home page and use size to display the lets go button -->
<!-- <div style="height:90vh"> -->
<div  [hidden]="finished">

  <!-- temp solution to move the list or home screen text down the page -->
<div style="margin-bottom: 50px;"></div>

<section fxLayout="column" fxFlexAlign="stretch"> 
    <div fxFlexAlign="center"> 
        <p>Only the Deals</p> 
    </div>

    <p>We search thousands of products every day to find you the best deals. </p> 

    <mat-tab-group (selectedTabChange)="executeSelectedChange($event)">
        <mat-tab label="What Deals"> 
          <p>What deals do we get for you? ....</p> 
        </mat-tab>
        <mat-tab label="From Where">
           <p>We search Tesco, Lidl, Aldi, Dunnes and Supervalue</p>
          </mat-tab>
        <mat-tab label="Searching">
           <p>We have designed some easy to use filters</p>
          </mat-tab>
      </mat-tab-group>
      <!-- <div class="hideShow text-center"  [@hideShowAnimator]="hideShowAnimator">
        I am out to fade In and Fade Out.
      </div> -->
     
</section>
</div>

<!-- <button libParticleEffectButton [pHidden]="hidden" (click)="hidden=!hidden">Lets Go..</button> -->
<div fxLayout="column" fxFlexAlign="stretch" > 
  <div fxFlexAlign="center"> 
    <!-- may consider removing padding-top: 70% not sure how responsive it is -->
    <div style="padding-top: 70%"></div>
  <button
        class="particles-button"
        libParticleEffectButton
        pStyle="stroke"
        pColor="#1b81ea"
        pDirection="bottom"
        pDuration="1200"
        pEasing="easeOutSine"
        [pSpeed]=".7"
        pOscillationCoefficient="5"
        [pHidden]="hidden"
        (click)="hidden=!hidden"
        (pComplete)="testRoute()"
      
      >
        Lets Go..
      </button>
      <!-- (pComplete)="finished=!finished"  code from above while testing-->
  </div>
  <div style="height:100px"></div>
</div>

<!-- <app-product [hidden]="!finished"></app-product> -->

<!-- </div> -->

