import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductEditComponent } from './product-edit/product-edit.component';
import { ProductService } from './product.service';
import { PRODUCT_ROUTES } from './product.routes';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material/material.module';
import { ProductViewComponent } from './product-view/product-view.component';
import { AppModule } from '../app.module';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';




@NgModule({ 
  declarations: [
    // ProductListComponent,
    // ProductEditComponent,
    // ProductViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(PRODUCT_ROUTES),
    MaterialModule,
    FlexLayoutModule,
    
  ],
  providers: [ProductService]
})
export class ProductModule { }
