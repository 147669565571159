
<!-- <ol class="breadcrumb float-right">
  <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
  <li class="breadcrumb-item active">Products</li>
</ol>
<div class="card">
  <div class="header">
    <h2 class="title">Products List</h2>
  </div>
  <div class="content">
    <form #f="ngForm">
      
      <div class="form-group">
        <label for="productName">ProductName:</label>
        <input [(ngModel)]="filter.productName" id="productName" name="productName" class="form-control">
      </div>
      
      <button (click)="search()" [disabled]="!f?.valid" class="btn btn-primary">Search</button>
      <a [routerLink]="['../products', 'new' ]" class="btn btn-default">New</a>
    </form>
  </div>
</div>
<div class="card" *ngIf="productList.length > 0">
  <div *ngIf="feedback" class="alert alert-{{feedback.type}}">{{ feedback.message }}</div>
  <div class="table-responsive">
    <table class="table table-centered table-hover mb-0" id="datatable">
      <thead>
        <tr>
          <th class="border-top-0" scope="col">Id</th>
          <th class="border-top-0" scope="col">ProductName</th>
          <th class="border-top-0" scope="col">ImageUrl</th>
          <th class="border-top-0" scope="col">Category</th>
          <th class="border-top-0" scope="col">PriceText</th>
          <th class="border-top-0" scope="col">OldPrice</th>
          <th class="border-top-0" scope="col">NewPrice</th>
          <th class="border-top-0" scope="col">StoreName</th>
          <th class="border-top-0" scope="col">DiscountAmount</th>
          <th class="border-top-0" scope="col">DiscountPercent</th>
          <th class="border-top-0" scope="col">StartDate</th>
          <th class="border-top-0" scope="col">EndDate</th>
          <th class="border-top-0" scope="col" style="width:120px"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of productList" [class.active]="item === selectedProduct">
        
          <td>{{item.id}}</td>
          <td>{{item.productName}}</td>
          <td>{{item.imageUrl}}</td>
          <td>{{item.category}}</td>
          <td>{{item.priceText}}</td>
          <td>{{item.oldPrice}}</td>
          <td>{{item.newPrice}}</td>
          <td>{{item.storeName}}</td>
          <td>{{item.discountAmount}}</td>
          <td>{{item.discountPercent}}</td>
          <td>{{item.startDate}}</td>
          <td>{{item.endDate}}</td>
          <td style="white-space: nowrap">
            <a [routerLink]="['../products', item.id ]" class="btn btn-secondary">Edit</a>&nbsp;
            <button (click)="delete(item)" class="btn btn-danger">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div> -->

<!-- temp solution to move the list down the page -->
<!-- <div style="margin-bottom: 50px;"></div>  -->



<!-- this may be better to give some space to see the first row of products -->
<div  style="height: 60px"></div>
<div class="content" fxLayout="row wrap" fxLayoutGap="16px grid" id ="matTable">
  <div fxFlex="20%" fxFlex.xs="50%" fxFlex.sm="33%" *ngFor="let item of productList">
  <mat-card class="mat-elevation-z4">
    <mat-card-header matBadge="{{item.discountPercent}}" (click)="onSelected(item)">
        <!-- <button mat-mini-fab color="primary" class="percentage">
          {{item.discountPercent}}%
        </button> -->
      <!-- if the product name is greater than 30 then stop there and add .. using the slice pipe. this helps keep the cards in line -->
      <!-- <mat-card-title style="min-height: 100px;">{{(item.productName.length>30)? (item.productName | slice:0:30)+'..':(item.productName)}}</mat-card-title> -->

      <mat-card-title>
        <!-- <span matBadge="{{item.discountPercent}}" matBadgeOverlap="false"> {{item.storeName}}</span> -->
      <!-- <button mat-raised-button matBadge="{{item.discountPercent}}">
        Discount
    </button> -->
</mat-card-title>
    </mat-card-header>
    <div (click)="onSelected(item)" >
      <img mat-card-image src="{{item.imageUrl}}">
    </div>
    <mat-card-content (click)="onSelected(item)">
      <p style="white-space: pre-line">
        <!-- {{formatProductName(item.productName)}} -->
        <!-- todo check why i had this commented out -->
        {{(item.productName.length>30)? (item.productName | slice:0:30)+'..':(item.productName)}}
      </p>
      <!-- <p>
      Old Price: {{item.oldPrice}}
      </p> -->
      <p>
        Price € {{item.newPrice}}
        </p>
        <!-- <p>
          discount percentage: {{item.discountPercent}}
          </p> -->
          <!-- <mat-icon>local_grocery_store</mat-icon>
          
            {{item.storeName}} -->
            <div class="valign-center">
              <i class="material-icons">local_grocery_store</i>
              <span> {{item.storeName}} </span>
            </div>         
    </mat-card-content>
    <mat-card-actions>
      <!-- <button mat-button>See More</button>
      <button mat-button matBadge="{{getAmountsaved(item)}}">Save</button> -->

     
      <!-- <mat-checkbox class="example-margin" [(ngModel)]="checked">Checked</mat-checkbox> -->
      <mat-slide-toggle  color="primary" (change)="onChange($event, item)">Add to list</mat-slide-toggle>

      <p>
        <!-- <span matBadge="{{getAmountsaved(item)}}" matBadgeSize="large">You Save</span> -->
        <span  fxLayoutAlign="end start">You Save {{getAmountsaved(item)}}</span>
      </p>
    </mat-card-actions>
  </mat-card>
</div>
</div>


<mat-paginator [pageSizeOptions]="[20, 50, 1000]" [pageSize]="20" [length]="totalElements"
(page)="nextPage($event)">

</mat-paginator>


