

<ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
 
 <button textAlign='center' (click)="findProductByRange()" >Search Range Now</button>
<mat-list>
<mat-list-item>
  <!-- <mat-slider aria-label="unit(s)"></mat-slider> -->
</mat-list-item>
<mat-list-item>
<div mat-line style="width: 100%; text-align: center;">Choose your discount.</div>
</mat-list-item>
</mat-list>
<mat-list>
    <mat-list-item>
      <span mat-line>10%</span>
      <mat-slide-toggle [disabled]="true">
    </mat-slide-toggle>
   </mat-list-item>
  
   <mat-list-item>
    <span mat-line>20%</span>
    <mat-slide-toggle [disabled]="true">
    </mat-slide-toggle>
 </mat-list-item>

 <!-- <mat-list-item>
  <span mat-line>50%</span>
  <mat-slide-toggle [(ngModel)]="value" [value]="50"></mat-slide-toggle>
</mat-list-item> -->

  <mat-list-item>
    <span mat-line>100%</span>
    <mat-slide-toggle [disabled]="true">
    </mat-slide-toggle>
</mat-list-item>


  </mat-list>
  <!-- <mat-button-toggle-group  #group="myFlagForButtonToggle"> -->
    <mat-button-toggle-group #group="matButtonToggleGroup">
    <mat-button-toggle value="10" (change)="onValChange($event.value)"  ngDefaultControl>upto 10%</mat-button-toggle>
    <mat-button-toggle value="40" (change)="onValChange($event.value)" ngDefaultControl>upto 40%</mat-button-toggle>
    <mat-button-toggle value="50" (change)="onValChange($event.value)" ngDefaultControl>50% and greater</mat-button-toggle>
  </mat-button-toggle-group>

  <!-- close the popup, the value has already been set in the event.value above -->
  <button mat-stroked-button color="primary" (click)="closeBottomSheetAndFlterOnDiscountPercent()">Done</button>
  {{group.value}}
