<mat-toolbar color="primary">
    <div fxHide.gt-xs>
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <!-- show different button when not in small screen, meybe a store select button xx -->
    <div fxHide.xs>
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>filter_list</mat-icon>
        </button>
    </div>
    <!-- <div>
        <a routerLink="/home">po</a>
    </div> -->

    <!-- creating a button in the middle of the mobile view -->
    <!-- fxHide.lg will hide on large screen. taking it out for now line was previously. see below -->
    <!-- <div fxFlex fxLayout fxLayoutAlign="center" fxHide.lg (click)="openBottomSheet()"> -->
        <div fxFlex fxLayout fxLayoutAlign="center" (click)="openBottomSheet()" *ngIf="!searchOpenState">
        <button mat-icon-button >
            <mat-icon matBadge={{userDiscountPercent}} matBadgeOverlap="false">filter_alt</mat-icon>
        </button>
    </div>
      <!-- adding a button for top products call in service
        <div [@fade]='status' fxFlex fxLayout fxLayoutAlign="center" (click)="topProducts()" *ngIf="!searchOpenState">
            <button mat-icon-button >
                <mat-icon>north</mat-icon>
            </button>
        </div> -->
         <!-- adding a button for top products call in service.
             repurposing this button to show the product count -->
         <div [@fade]='status' fxFlex fxLayout fxLayoutAlign="center" (click)="openProductBottomSheet()" *ngIf="!searchOpenState">
           <p matBadge="5K">Updated {{createdDate | date }}</p>
        </div>
        <ng9-odometer [number]="totalSavingsNumber | number:'1.2-2'" [config]="{ format: '(,ddd).dd', theme: 'default' }"></ng9-odometer>

        <!-- keep the search to the right -->
    <!-- <div fxFlex fxLayout fxLayoutAlign="end" fxHide.gt-xs>
        <ul fxLayout fxLayoutGap="15px" class="navigation-items">
            <li>
                <mat-search-bar
                (onOpen)="onToggleSearchState()"
                (onBlur)="onToggleSearchState()"
                [matAutocomplete]="simpleAuto"
                (onEnter)="test($event)"
                ></mat-search-bar>
            
            </li>
        </ul>
    </div> -->
<!-- for search  -->
    <div class="container">
  
        <form [formGroup]="formGroup">
          <mat-form-field>
            <mat-label>
              Search Here
            </mat-label>
            <input formControlName="productSearch" matInput type="text" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let item of filteredOptions" [value]="item" (onSelectionChange)="selectedOption(item)">
                {{item}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
      
    </div>



    <!-- fxhide.xs must hide for mobile view -->
    <div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs> 
        <ul fxLayout fxLayoutGap="15px" class="navigation-items">
            <li>
                Hi {{authService.name}}
              
                <!-- <mat-search-bar
                (onOpen)="onToggleSearchState()"
                (onBlur)="onToggleSearchState()"
                [matAutocomplete]="simpleAuto"
                (onEnter)="test($event)"
        
               
          
                ></mat-search-bar> -->

                <!--
                     (onClose)="onToggleSearchState()"
                    (onBlur)="onToggleSearchState()"
                [formControl]="myControl"  -->

            </li>
            <li>
                <mat-autocomplete #simpleAuto="matAutocomplete">
                    <mat-option *ngFor="let option options" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
            </li>
            <li>
                <!-- <button (click)="showUser()">
                Hi {{userName}}</button> -->
                <!-- <a routerLink="/products">Deals</a> -->
            </li>
            <li>
                <!-- <a routerLink="/account">Account Actions</a> -->
               
            </li>
        </ul>
    
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>menu_open</mat-icon>
        </button>
        <!-- <button class="hamburger hamburger--collapse" type="button">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button> -->
    </div>
  </mat-toolbar>

 
 
  <!-- <bottom-sheet (triggerPercentageDiscountAPICallEvent)="test()"></bottom-sheet> -->
    