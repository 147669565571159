import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/*
for authentication during testing the following changes were made:
2 files auth.service
2 files auth,guard
login component
changes to the app-routing
*/
export class AuthService {
  isAuthenticate = false;
  name = 'User';

  login(user: string, password: string): boolean {
    console.log("inside login in the auth service")
    if (user.toLowerCase() === 'ena-mai' && password === 'tester16') {
      console.log("inside login and if in the auth service")
      this.isAuthenticate = true;
      this.name = 'Ena-Mai';
      return this.isAuthenticate;
    }
    this.isAuthenticate = false;
    return this.isAuthenticate;
  }
}
