import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductModule } from './product/product.module';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material/material.module';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { LayoutComponent } from './navigation/layout/layout.component';
import { BottomSheetComponent } from './bottom-sheet/bottom-sheet.component';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ProductEditComponent } from './product/product-edit/product-edit.component';
import { ProductListComponent } from './product/product-list/product-list.component';
import { ProductViewComponent } from './product/product-view/product-view.component';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgMatSearchBarModule } from 'ng-mat-search-bar';

import { ParticleEffectButtonModule } from "angular-particle-effect-button";
import { BottomSheetProductComponent } from './bottom-sheet-product/bottom-sheet-product.component';
import { LoginComponent } from './login/login.component';

import { MatSelectModule } from '@angular/material/select';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { Ng2OdometerModule } from 'ng2-odometer';
import { Ng9OdometerModule } from 'ng9-odometer';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SidenavListComponent,
    LayoutComponent,
    BottomSheetComponent,
    ProductListComponent,
    ProductEditComponent,
    ProductViewComponent,
    BottomSheetProductComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ProductModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    MatBottomSheetModule,
    NgMatSearchBarModule,
    ParticleEffectButtonModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgxSliderModule,
    Ng9OdometerModule.forRoot()

  ],
  providers: [
// not sure needed to add these was getting bottomsheet error taken from here:
// https://stackoverflow.com/questions/57846500/nullinjectorerror-no-provider-for-matbottomsheetref
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
  ],
  entryComponents: [BottomSheetComponent], // this is for the bottom sheet pop up
  bootstrap: [AppComponent]
})
export class AppModule { }
