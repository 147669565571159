import { Product } from './product';
import { ProductFilter } from './product-filter';
import { Injectable } from '@angular/core';
import { from, Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

const headers = new HttpHeaders().set('Accept', 'application/json');

@Injectable()
export class ProductService {
  // to hold total elements on page for pagenation.
  totalElements = 0; // possibly not in use
  productList: Product[] = [];
  currentProduct: Product | null;  // current selected product shared by components
  userDiscountPercent: number; // amaout selected by user
  isTopProducts = true; // used to determine if the call is top product. For pagenation
  isSearchProducts = false; // used to determine if the call is a searchable product. For pagenation setting to false so top products can be loaded first
  selectedStoreName: String; // used to get products by single store name
  
  // create a subject and make it an observable. for resetting pagenation
  isTopProductSubject = new Subject<string>();
  isTopProduct$ = this.isTopProductSubject.asObservable();

  
  // api = 'http://localhost:8080/api/products';
 
  // this is the pagable controller in spring
  // api2 = 'http://192.168.43.151:9090/api/products-pageable';

  api = 'https://www.api.onlythedeals.com/api/products';
  api2 = 'https://www.api.onlythedeals.com/api/products-pageable'; 
  api3 = 'https://www.api.onlythedeals.com/api/products/findbydiscount'; 
  api4 = 'https://www.api.onlythedeals.com/api/top-products';  
  api5 = 'https://www.api.onlythedeals.com/api/store';  // get products by store name
  api6 = 'https://www.api.onlythedeals.com/api/products/findbyexactdiscount';  // get products by exact discount used for testing
  api7 = 'https://www.api.onlythedeals.com/api/products/findbydiscountpercentbyrange';  // get products by range discount used for testing
  api8 = 'https://www.api.onlythedeals.com/api/search-containing-name';  // get products by search name


  // api = 'http://localhost:8080/api/products';
  // api2 = 'http://localhost:8080/api/products-pageable'; 
  // api3 = 'http://localhost:8080/api/products/findbydiscount'; 
  // api4 = 'http://localhost:8080/api/top-products';  
  // api5 = 'http://localhost:8080/api/store/';  // get products by store name
  // api6 = 'http://localhost:8080/api/products/findbyexactdiscount';  // get products by exact discount used for testing
  // api7 = 'http://localhost:8080/api/products/findbydiscountpercentbyrange';  // get products by range discount used for testing
  // api8 = 'http://localhost:8080/api/search-containing-name';  // get products by search name


  constructor(private http: HttpClient) {
  }

  // populate the observable with a string that can be used in the product list to clear the pagenation pages
  sendisTopProductString(isTopProduct) {
    this.isTopProductSubject.next(isTopProduct);
  }

  listProducts(request) {
    const endpoint = this.api2;
    const params = request;
    return this.http.get(endpoint, { params });
  }

  findById(id: string): Observable<Product> {
    const url = `${this.api}/${id}`;
    const params = { id: id };
    return this.http.get<Product>(url, {params, headers});
  }

  // enter a discount percent and find all products. note this should replace the current product list
  loadFindByDiscountPercent(request): Observable<Product[]> {
    const url = `${this.api3}?discountPercent=${this.userDiscountPercent}`;
    const params = request;
    return this.http.get<Product[]>(url, {params});
  }


  findProductByExactPercent(request): void {
    this.loadFindByExactDiscountPercent(request).subscribe(data => {
        this.productList = data['content'];
          this.totalElements = data['totalElements'];
          // this.loading = false;
        }, error => {
          // this.loading = false;
        });
    }

    findProductByRange(request, min, max): void {
      this.loadFindByRangeDiscountPercent(request, min, max).subscribe(data => {
          this.productList = data['content'];
            this.totalElements = data['totalElements'];
            // this.loading = false;
          }, error => {
            // this.loading = false;
          });
      }

        // enter a discount percent and find all products. note this should replace the current product list
    loadFindByRangeDiscountPercent(request, min, max): Observable<Product[]> {
      const url = `${this.api7}?discountMinPercent=${min}&discountMaxPercent=${max}`;
      const params = request;
      return this.http.get<Product[]>(url, {params});
    }

    // enter a discount percent and find all products. note this should replace the current product list
    loadFindByExactDiscountPercent(request): Observable<Product[]> {
      const url = `${this.api6}?discountPercent=${this.userDiscountPercent}`;
      const params = request;
      return this.http.get<Product[]>(url, {params});
    }

  // load to get all products for a store
  loadFindByStoreName(storeName): Observable<Product[]> {
    // todo change variable storeName
    const url = `${this.api5}/${storeName}`;
    // const params = request;
    return this.http.get<Product[]>(url);
  }

  findByDiscountPercent(request): void {
    this.loadFindByDiscountPercent(request).subscribe(data => {
        this.productList = data['content'];
          this.totalElements = data['totalElements'];
          // this.loading = false;
        }, error => {
          // this.loading = false;
        });
    }

    // method to get all products for a store
    getProductsByStoreName(request): void {
      this.loadFindByStoreName(request).subscribe(data => {
          this.productList = data['content'];
            this.totalElements = data['totalElements'];
            // this.loading = false;
          }, error => {
            // this.loading = false;
          });
      }

    // this method will get the top products for the products page when first opened 
    // creating a void method as it will be shared with the product list component.
    
    getTopProducts(request): void {   
      this.loadTopProducts(request).subscribe(data => {
        this.productList = data['content'];
          this.totalElements = data['totalElements'];
          // this.loading = false;
        }, error => {
          // this.loading = false;
        });
    }
  
     // call top products API 
  loadTopProducts(request): Observable<Product[]> {
    const url = `${this.api4}`;
    const params = request;
    return this.http.get<Product[]>(url, {params});
  }

  load(filter: ProductFilter): void {
    this.find(filter).subscribe(result => {
        this.productList = result;
      },
      err => {
        console.error('error loading', err);
      }
    );
  }

  find(filter: ProductFilter): Observable<Product[]> {
    const params = {
      'productName': filter.productName,
    };

    return this.http.get<Product[]>(this.api, {params, headers});
  }

  getData(){
    return this.http.get('http://localhost:8080/api/products/names')
      .pipe(
        map((response:[]) => response.map(item => item['productName']))
      )
  }

      // method to get all products given a search name
      getProductsBySearchName(option,  request?): void {
        this.loadFindBySearchName(option, request).subscribe(data => {
            this.productList = data['content'];
              this.totalElements = data['totalElements'];
              // this.loading = false;
            }, error => {
              // this.loading = false;
            });
        }

   // load to get all products for givin a search name
   loadFindBySearchName(option, request): Observable<Product[]> {
    // todo change variable searchName
    const url = `${this.api8}/${option}`;
    const params = request;
    return this.http.get<Product[]>(url, {params});
  }

  // save(entity: Product): Observable<Product> {
  //   let params = new HttpParams();
  //   let url = '';
  //   if (entity.id) {
  //     url = `${this.api}/${entity.id.toString()}`;
  //     params = new HttpParams().set('ID', entity.id.toString());
  //     return this.http.put<Product>(url, entity, {headers, params});
  //   } else {
  //     url = `${this.api}`;
  //     return this.http.post<Product>(url, entity, {headers, params});
  //   }
  // }

  // delete(entity: Product): Observable<Product> {
  //   let params = new HttpParams();
  //   let url = '';
  //   if (entity.id) {
  //     url = `${this.api}/${entity.id.toString()}`;
  //     params = new HttpParams().set('ID', entity.id.toString());
  //     return this.http.delete<Product>(url, {headers, params});
  //   }
  //   return null;
  // }
}

