<mat-nav-list>
    <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
        <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
    </a>
    <a mat-list-item routerLink="/products" (click)="onSidenavClose()">
        <mat-icon>info</mat-icon> <span class="nav-caption">What we do</span>   
    </a>
    <!-- <mat-list-item>
        <mat-checkbox checked="true" labelPosition="before">Tesco</mat-checkbox>
    </mat-list-item>
    <mat-list-item>
        <mat-checkbox checked="true" labelPosition="before">SuperValu</mat-checkbox>
    </mat-list-item>
    <mat-list-item>
        <mat-checkbox checked="true" labelPosition="before">Dunnes Stores</mat-checkbox>
    </mat-list-item>
    <button mat-menu-item (click)="onSidenavClose()">Done</button> -->

    <!-- not working, not able to select one  -->
    <!-- <mat-selection-list #item  [multiple]="false">
        <mat-list-option>Tesco</mat-list-option>
        <mat-list-option>SuperValu</mat-list-option>
        <mat-list-option>Dunnes Stores</mat-list-option>
      </mat-selection-list>
      <button mat-menu-item (click)="onSidenavClose()">Done</button>
      <p>
        Options selected: {{item.selectedOptions.selected.length}}
      </p> -->

      <!-- temp solution -->
      <!-- <mat-form-field appearance="fill">
        <mat-label>Select an store</mat-label>
        <mat-select [(value)]="selected">
          <mat-option>None</mat-option>
          <mat-option value="SuperValu">SuperValu</mat-option>
          <mat-option value="Dunnes Stores">Dunnes Stores</mat-option>
          <mat-option value="Tesco">Tesco</mat-option>
        </mat-select>
      </mat-form-field>
      
      <p>You selected: {{selected}}</p>
      <button mat-menu-item (click)="getProductsByStoreName()">Done</button>


    <mat-list-item [matMenuTriggerFor]="menu">
        <mat-icon>unfold_more</mat-icon>
        <a matline>Store</a>
    </mat-list-item>
    <mat-menu #menu="matMenu">
       <button mat-menu-item (click)="onSidenavClose()">Tesco</button>
      <button mat-menu-item (click)="onSidenavClose()">SuperValu</button>
      <button mat-menu-item (click)="onSidenavClose()">dunnes Stores</button>
      <button mat-menu-item (click)="onSidenavClose()">Aldi</button>
      <button mat-menu-item (click)="onSidenavClose()">Lidl</button> -->
      <!-- <mat-selection-list [disableRipple]="true" #item>
        <mat-list-option>Tesco</mat-list-option>
        <mat-list-option>SuperValu</mat-list-option>
        <mat-list-option>Dunnes Stores</mat-list-option>
      </mat-selection-list>
    </mat-menu>
    <a mat-list-item routerLink="#" (click)="onSidenavClose()">
        <mat-icon>show_chart</mat-icon><span class="nav-caption">Insight</span>
    </a>  -->


    <mat-form-field appearance="none">
        <mat-label>Select an store</mat-label>
        <mat-select [(value)]="selected">
          <mat-option>None</mat-option>
          <mat-option value="SuperValu">SuperValu</mat-option>
          <mat-option value="Dunnes Stores">Dunnes Stores</mat-option>
          <mat-option value="Tesco">Tesco</mat-option>
        </mat-select>
      </mat-form-field>
      
      <p>You selected: {{selected}}</p>
      <button mat-menu-item (click)="getProductsByStoreName()">Done</button>


</mat-nav-list>

