import { Component, ViewChild} from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Product } from '../product/product';
import { ProductService } from '../product/product.service';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.css']
})
// this bottom sheet will let the user pick which value to filter the percentage e.g. 50% 100% etc
export class BottomSheetComponent {

  autoTicks = false;
  disabled = false;
  invert = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;
  vertical = false;
  tickInterval = 1;

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }

    // // 
    // get topProducts(): boolean {
    //   return this.productService.isTopProducts;
    // }

  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,  private productService: ProductService) {
  }

  userPercentDiscount: number;
  myFlagForButtonToggle :string;

  // ngx slider
  minValue: number = 0;
  maxValue: number = 100;
  // sliderValue: number = 100;
  options: Options = {
    floor: 0,
    ceil: 100
  };

  // code doesnt seem to hit this method
  openLink(event: MouseEvent): void {
    // console.log('method open link in bottom sheet' , this.userPercentDiscount)
    this._bottomSheetRef.dismiss(); // add data in the dismiss() param to pass back to the page
    event.preventDefault();
    // console.log('before closing bottom sheet the value is' , this.userPercentDiscount)
  }

  onValChange(value){
    // add the user discount percent to the product service field. used for sharing between components
    this.productService.userDiscountPercent = value;
    // this._bottomSheetRef.dismiss(this.userPercentDiscount);
}

// use this click event to call the shared producd service's get products findByDiscountPercent method.
// this will be the first call and any subsequent calls will made in the Product.ts file for pagenation.
// so this method will be called when a new list of products is required.
closeBottomSheetAndFlterOnDiscountPercent(){
  // make the call to the product service with harcoded pagenation
  this.productService.findByDiscountPercent({ page: "0", size: "20" }); 

  // turn off the call to top products for pagenation
  this.productService.isTopProducts = false;

    // call the reset pegenation in product-list.ts by changing the string observable in the product service
  this.productService.sendisTopProductString("topProducts");
  
  this._bottomSheetRef.dismiss();
  }

  findProductByExactPercent(value){
   
    console.log('value discount', value);
    
    this.productService.userDiscountPercent = value;

    this.productService.findProductByExactPercent({ page: "0", size: "20" }); 
    this._bottomSheetRef.dismiss();
  }

  findProductByRange(){
   
    console.log('min', this.minValue);
    console.log('max', this.maxValue);
    
    // this.productService.userDiscountPercent = value;

    this.productService.findProductByRange({ page: "0", size: "20" }, this.minValue, this.maxValue); 
    this._bottomSheetRef.dismiss();
  }
}
