import { Component, OnInit } from '@angular/core';
import {trigger,state,style,animate,transition,keyframes} from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',

  animations: [
    trigger('hideShowAnimator', [
        state('true' , style({ opacity: 1 })), 
        state('false', style({ opacity: 0 })),
        transition('0 => 1', animate('3s')),
        transition('1 => 0', animate('9s'))
    ])
  //   ,
  //   trigger('hideProductListSwitch', [
  //     state('true' , style({ opacity: 1 })), 
  //     state('false', style({ opacity: 0 })),
  //     transition('0 => 1', animate('3s')),
  //     transition('1 => 0', animate('.9s'))
  // ])
  ],
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  hidden: boolean = false; // not needed in demo for particle button
  finished: boolean = false; // not needed in demo for particle button
  hideShowAnimator:boolean = true;
  // hideProductList:boolean = false;
  hideShowAnimation(){
    this.hideShowAnimator = !this.hideShowAnimator;
}

//   hideProductListSwitch(){
//   this.hideProductList = !this.hideProductList;
// }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public executeSelectedChange = (event) => {
    console.log(event);
  }

  public test(event) {
    console.log("testing pComplete", event);
  }
  public testRoute(): void {
    this.router.navigateByUrl('/products');
  }

}
