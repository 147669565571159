<p>Total Deals found today is 5344</p>
<mat-list>
    <mat-list-item>
      <span mat-line>Tesco</span>
      <!-- <mat-slide-toggle [disabled]="true">
    </mat-slide-toggle> -->
    <mat-chip-list aria-label="Fish selection">
        <mat-chip color="primary" selected>2246</mat-chip>
    </mat-chip-list>
   </mat-list-item>
  
   <mat-list-item>
    <span mat-line>SuperValu</span>
    <!-- <mat-slide-toggle [disabled]="true">
    </mat-slide-toggle> -->
    <mat-chip-list aria-label="Fish selection">
        <mat-chip color="primary" selected>746</mat-chip>
    </mat-chip-list>
 </mat-list-item>

 <!-- <mat-list-item>
  <span mat-line>50%</span>
  <mat-slide-toggle [(ngModel)]="value" [value]="50"></mat-slide-toggle>
</mat-list-item> -->

  <mat-list-item>
    <span mat-line>Dunnes Stores</span>
    <!-- <mat-slide-toggle [disabled]="true">
    </mat-slide-toggle> -->
    <mat-chip-list aria-label="Fish selection">
    <mat-chip color="primary" selected>1546</mat-chip>
</mat-chip-list>
</mat-list-item>


  </mat-list>
