import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  // public totalSavingsNumber: number;
  // constructor() {
  //   this.totalSavingsNumber = 5;
  //  }

  //  setTotalSavingsNumber(totalSavingsNumber: number){
  //   this.totalSavingsNumber = totalSavingsNumber;
  //  }

  //  getTotalSavingsNumber(){
  //  return this.totalSavingsNumber ;
  //  }

   private totalSavingsNumber: BehaviorSubject<number>;

   public searchName: String;

  constructor() {
    this.totalSavingsNumber = new BehaviorSubject<number>(0);
  }

  getValue(): Observable<number> {
    return this.totalSavingsNumber.asObservable();
  }
  setValue(newValue): void {
    this.totalSavingsNumber.next(newValue);
  }

  addValue(newValue): void {

    this.setValue( +this.totalSavingsNumber.value + newValue );
  }

  subtractValue(newValue): void {
    this.setValue( +this.totalSavingsNumber.value - newValue);
  }
  
}
