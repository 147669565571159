<div *ngIf='product'>
  <!-- added the mat dialog tag to make the pop out scrollable. consider replacing the card tags
  to dialog tags for better responsivness especially in landscape mode -->
  <mat-dialog-content>
<mat-card class="mat-elevation-z4">
    <mat-card-header>
      <mat-card-title style="min-height: 100px;">{{product.productName}}</mat-card-title>
    </mat-card-header>
    <img style="height:100%; width:100%" mat-card-image src="{{product.imageUrl}}">
    <mat-card-content>
      <p>
      Old Price: {{product.oldPrice}}
      </p>
      <p>
        New Price: {{product.newPrice}}
        </p>
        <p>
          discount percentage: {{product.discountPercent}}
          </p>
          <p>
            store: {{product.storeName}}
            </p>
            <!-- to use the /n passed from the api we use innerText to allow the line break -->
            <p
              [innerText]='product.priceText'>
            </p>
            <p>
             
            </p>
    </mat-card-content>
    <mat-card-actions>
     
    </mat-card-actions>
  </mat-card>
</mat-dialog-content>
</div>
