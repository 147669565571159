import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ProductService } from '../product.service';
import { Product } from '../product';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html'
})
export class ProductEditComponent implements OnInit {

  id: string;
  product: Product;
  feedback: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService) {
  }

  ngOnInit() {
    this
      .route
      .params
      .pipe(
        map(p => p.id),
        switchMap(id => {
          if (id === 'new') { return of(new Product()); }
          return this.productService.findById(id);
        })
      )
      .subscribe(product => {
          this.product = product;
          this.feedback = {};
        },
        err => {
          this.feedback = {type: 'warning', message: 'Error loading'};
        }
      );
  }
  // save() {
  //   this.productService.save(this.product).subscribe(
  //     product => {
  //       this.product = product;
  //       this.feedback = {type: 'success', message: 'Save was successful!'};
  //       setTimeout(() => {
  //         this.router.navigate(['/products']);
  //       }, 1000);
  //     },
  //     err => {
  //       this.feedback = {type: 'warning', message: 'Error saving'};
  //     }
  //   );
  // }

  cancel() {
    this.router.navigate(['/products']);
  }
}
