import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { Product } from '../product';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.css']
})
// this class will initially be a popup to show the product full information.
// For now no information will be passed but the product will be read from a 
// service which will use angular change detection to update the Product.
export class ProductViewComponent implements OnInit {

  // get the current product from the product service
  get product(): Product {
    // console.log('product ', this.productService.currentProduct);
    
    //  this.productService.currentProduct.priceText.replace('/n', '<br>');
    //  console.log('product ', this.productService.currentProduct);
    return this.productService.currentProduct;
  }

  // ref the popup
  constructor(  private productService: ProductService, public dialogRef: MatDialogRef<ProductViewComponent>) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  

}
