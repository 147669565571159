import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProductService } from 'src/app/product/product.service';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();

  selected = '';

  labelPosition: 'before';

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
  }
/**
 * method to call the product service with a single store name
 */
  public getProductsByStoreName = () => {
    this.productService.getProductsByStoreName(this.selected);
    this.onSidenavClose();
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

}
